'use client';
import React from 'react';

// import Swiper core and required modules
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, A11y, Autoplay, EffectFade } from 'swiper/modules';
import { type Swiper as SwiperType, type SwiperOptions } from 'swiper/types';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import sidekick from '@last-rev/contentful-sidekick-util';

import Grid from '../Grid';
import ErrorBoundary from '../ErrorBoundary';
import ContentModule from '../ContentModule';

import type { CarouselHeroProps, CarouselHeroOwnerState } from './CarouselHero.types';
import Background from '../Background';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/effect-fade';

const CarouselHero = (props: CarouselHeroProps) => {
  const ownerState = { ...props };
  const [loaded, setLoaded] = React.useState(false);
  const [thumbsSwiper, setThumbsSwiper] = React.useState<SwiperType | null>(null);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      setLoaded(!!thumbsSwiper);
    }, 1_000);
    return () => clearTimeout(timeout);
  }, [thumbsSwiper]);

  const {
    backgroundImage,
    backgroundColor,
    carouselAutoPlay,
    disclaimerText,
    items,
    variant,
    sidekickLookup,
    introText
  } = props;

  const swiperBreakpoints: { [width: number]: SwiperOptions } = {};
  let spaceBetween = 24;

  // Can change the slidePerView below if you don't want to show a partial for the next slide
  swiperBreakpoints['1'] = {
    slidesPerView: 1,
    spaceBetween
  };

  return (
    <ErrorBoundary>
      <Root
        ownerState={ownerState}
        {...sidekick(sidekickLookup)}
        data-testid={`CarouselHero-${variant}`}>
        <CarouselHeroBackground
          background={backgroundImage}
          backgroundColor={backgroundColor}
          testId="CarouselHero-background"
        />

        <>
          {introText && (
            <IntroTextGrid ownerState={ownerState}>
              <IntroText
                ownerState={ownerState}
                {...sidekick(sidekickLookup, 'introText')}
                {...introText}
                textVariant="introText"
              />
            </IntroTextGrid>
          )}

          <ContentGrid ownerState={ownerState}>
            <SwiperWrap ownerState={ownerState}>
              <SwiperInnerWrap ownerState={ownerState}>
                <Swiper
                  onSwiper={(s) => setThumbsSwiper(s)}
                  rewind={true}
                  breakpointsBase="container"
                  effect={'fade'}
                  modules={[EffectFade, Navigation, Pagination, A11y, Autoplay]}
                  spaceBetween={24}
                  breakpoints={swiperBreakpoints}
                  pagination={{ clickable: true }}
                  navigation
                  autoplay={
                    carouselAutoPlay
                      ? { disableOnInteraction: false, pauseOnMouseEnter: true }
                      : false
                  }>
                  {items?.map((item, index) => (
                    <SwiperSlide key={item?.id}>
                      <Item
                        index={index}
                        backgroundColor={backgroundColor}
                        loaded={loaded}
                        ownerState={ownerState}
                        {...item}
                        variant={'carouselHeroItem'}
                      />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </SwiperInnerWrap>
            </SwiperWrap>

            {!!disclaimerText && (
              <DisclaimerText
                {...sidekick(sidekickLookup, 'disclaimerText')}
                body={disclaimerText}
                __typename="RichText"
                data-testid="Collection-disclaimerText"
                variant="body2"
                ownerState={ownerState}
              />
            )}
          </ContentGrid>
        </>
      </Root>
    </ErrorBoundary>
  );
};

const MediaSwiper = styled(Swiper, {
  name: 'CarouselHero',
  slot: 'MediaSwiper',
  overridesResolver: ({ ownerState }, styles) => [
    styles.mediaSwiper,
    styles[`${ownerState?.variant}`]
  ]
})<{ ownerState: CarouselHeroOwnerState }>`
  max-width: 400px;
`;

const Root = styled(Box, {
  name: 'CarouselHero',
  slot: 'Root',
  overridesResolver: ({ ownerState }, styles) => [styles.root, styles[`${ownerState?.variant}`]]
})<{ ownerState: CarouselHeroOwnerState }>``;

const CarouselHeroBackground = styled(Background, {
  name: 'CarouselHero',
  slot: 'Background',
  overridesResolver: (_, styles) => [styles.background]
})<{}>``;

const ContentGrid = styled(Grid, {
  name: 'CarouselHero',
  slot: 'ContentGrid',
  overridesResolver: (_, styles) => [styles.contentGrid]
})<{ ownerState: CarouselHeroOwnerState }>``;

const IntroTextGrid = styled(Grid, {
  name: 'CarouselHero',
  slot: 'IntroTextGrid',
  overridesResolver: (_, styles) => [styles.introTextGrid]
})<{ ownerState: CarouselHeroOwnerState }>``;

const IntroText = styled(ContentModule, {
  name: 'CarouselHero',
  slot: 'IntroText',
  overridesResolver: (_, styles) => [styles.introText]
})<{ ownerState: CarouselHeroOwnerState }>``;

const SwiperWrap = styled(Box, {
  name: 'CarouselHero',
  slot: 'SwiperWrap',
  overridesResolver: (_, styles) => [styles.swiperWrap]
})<{ ownerState: CarouselHeroOwnerState }>``;

const SwiperInnerWrap = styled(Box, {
  name: 'CarouselHero',
  slot: 'SwiperInnerWrap',
  overridesResolver: (_, styles) => [styles.swiperInnerWrap]
})<{ ownerState: CarouselHeroOwnerState }>``;

const Item = styled(ContentModule, {
  name: 'CarouselHero',
  slot: 'Item',
  overridesResolver: (_, styles) => [styles.item]
})<{ ownerState: CarouselHeroOwnerState }>``;

const DisclaimerText = styled(ContentModule, {
  name: 'Collection',
  slot: 'DisclaimerText',
  overridesResolver: (_, styles) => [styles.disclaimerText]
})<{ ownerState: CarouselHeroOwnerState }>``;
export default CarouselHero;
